@media (max-width: 1279px){.a2a_kit {
  bottom: 120px;
}

.addtoany_share .a2a_svg {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
}

.addtoany_share .a2a_svg::after {
  font-size: 49px;
}

#banner .nav-primary ul>li:last-of-type {
  display: none;
}

.nav-primary {
  padding: 40px var(--container-padding) var(--container-padding);
  position: fixed;
  transform: translate3d(0, 0, 0);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: var(--backgroundColorOpacityTheme);
  -webkit-mask-image: linear-gradient(to top, #000000 80%, transparent 100%);
  mask-image: linear-gradient(to top, #000000 80%, transparent 100%);
}

.nav-primary::before,
.nav-primary::after {
  top: 0;
  transform: translate3d(0, 0, 0);
  background-position-y: 100%;
}

.nav-primary .cnav>ul>li:nth-child(5) {
  margin-right: 0;
}

.primary-bis {
  white-space: nowrap;
}

.fake-breadcrumbs span {
  font-size: 20px;
}

.card-info .button,
.card-info .tnp-field .tnp-submit,
.tnp-field .card-info .tnp-submit {
  margin-right: 14px;
}

.card-contact {
  font-size: 17px;
}

.card-contact h2 {
  font-size: 24px;
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70vh;
  z-index: 1;
  background-color: var(--backgroundColorTheme);
}

#annuaire_list {
  position: relative;
  margin-top: calc(70vh - 60px);
}

#footer {
  margin-top: 120px;
  padding-bottom: 120px;
  text-align: center;
}

#footer .logo {
  margin-bottom: 20px;
}

#footer .nav-rs {
  padding-top: 20px;
  border-top: 1px solid var(--colorTheme);
  margin-bottom: 16px;
}

#footer #nav-secondary,
#footer address {
  border-top: 1px solid var(--colorTheme);
  padding: 20px 0;
}

input {
  --inputHeight: 47px;
}

#lexique-search {
  margin-bottom: 16px;
}

:root {
  --default-font-size: 17px;
  line-height: 37px;
}

h1,
.h1,
.page-title {
  font-size: 35px;
  line-height: 35px;
}

.article_title {
  font-size: 27px;
  line-height: 31px;
}

h2,
.h2 {
  font-size: 20px;
  line-height: 23px;
}

h3,
.h3 {
  font-size: 17px;
  line-height: 21px;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-size: var(--default-font-size);
  line-height: 21px;
}

.h7 {
  font-size: 12px;
  line-height: 15px;
}

body.variant-background::after,
body.variant-background::before {
  animation: movebg var(--animation-background-position) ease infinite;
}

@-webkit-keyframes movebg {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 0% 35%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@-moz-keyframes movebg {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 0% 35%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@keyframes movebg {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 0% 35%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.notification .activate {
  padding-bottom: 2em;
}

.thumbnail-container {
  margin-bottom: 22px;
}

.thumbnail-container img {
  max-height: 200px;
}

.infos p {
  margin-bottom: 0;
}

.slash {
  display: none;
}}